import type { NextPage } from "next";

import Container from "../components/layout/Container";
import Main from "../components/layout/Main";

const Home: NextPage = () => {
  return (
    <Main>
      <Container>
        <div className="max-w-sm m-auto">
          <p className="text-xl font-medium mb-3 mt-12">Welcome to Thickt</p>
          <p>
            Please scan the QR code on an item to see more information and place
            a bid
          </p>
        </div>
      </Container>
    </Main>
  );
};

export default Home;
